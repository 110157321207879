// Videocomponent
import React, { useCallback } from "react";
import { useMedia } from "react-chromecast";

const mediaSrc0 =
  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4";

const mediaSrc =
  // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4";
  //   "https://mypdfbucket123.s3.eu-west-2.amazonaws.com/bored.jpeg";
  //   "https://mypdfbucket123.s3.eu-west-2.amazonaws.com/whatthey.MP3";
  "https://mypdfbucket123.s3.eu-west-2.amazonaws.com/bored.mp4";

function Videocomponent() {
  const media = useMedia();
  const playVideo = useCallback(async () => {
    if (media) {
      await media.playMedia(mediaSrc);
      await media.addMedia(mediaSrc0);
      await media.playMedia(mediaSrc0);
    }
  }, [media]);
  return (
    <>
      <button onClick={playVideo}>Play</button>
    </>
  );
}

export default Videocomponent;
