// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

/* src/App.js */
import React, { useEffect, useState, useCallback } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { createTodo, updateTodo } from "./graphql/mutations";
import { listTodos } from "./graphql/queries";
import { onCreateTodo, onUpdateTodo } from "./graphql/subscriptions";
import Car from "./Car.js";
import CastButton from "./CastButton.js";
import Videocomponent from "./ Videocomponent.js";
import CastProvider from "react-chromecast";
import LazyLoad from "react-lazyload";
// import { useMedia } from "react-chromecast";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
// import LazyLoad from "react-lazyload";
// import Ticker from "react-ticker";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const initialState = { name: "", description: "" };

const App = () => {
  const [formState, setFormState] = useState(initialState);
  const [todos, setTodos] = useState([]);
  const [url, setUrl] = useState("");
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [udpdatedAt, setUpdatedAt] = useState("");

  useEffect(() => {
    fetchTodos();
  }, []);

  useEffect(() => {
    onCreateTodos();
  }, []);

  // Todo-f67ozy7mpfa2bjmbl5vrwuxoqq-dev

  // const mediaSrc =
  //   "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4";

  // const media = useMedia();
  // const playVideo = useCallback(async () => {
  //   if (media) {
  //     await media.playMedia(mediaSrc);
  //   }
  // }, [media]);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos));
      console.log(todoData);
      const todos = todoData.data.listTodos.items;
      setTodos(todos);
      console.log(todos);
      let el = todos[0];
      console.log(el);
      setUrl(el.description);
      setName(el.name);
      setPrice(el.price);
      setUpdatedAt(el.updatedAt);
    } catch (err) {
      console.log(err);
      console.log("error fetching todos");
    }
  }
  async function onCreateTodos() {
    try {
      // const todoData = await API.graphql(graphqlOperation(listTodos));
      // const todos = todoData.data.listTodos.items;
      // setTodos(todos);
      const subscription = API.graphql(
        graphqlOperation(onUpdateTodo)
      ).subscribe({
        next: (todoData) => {
          // let el = todoData.value;
          let el = todoData.value.data.onUpdateTodo;
          console.log(el);
          console.log(Object.keys(el));
          console.log("in update");
          setUrl(el.description);
          setName(el.name);
          setPrice(el.price);
          setUpdatedAt(el.updatedAt);
          // Do something with the data
        },
      });
    } catch (err) {
      console.log("error fetching todos");
    }
  }

  // // Subscribe to creation of Todo
  // const subscription = API.graphql(graphqlOperation(onCreateTodo)).subscribe({
  //   next: (todoData) => {
  //     console.log(todoData);
  //     // Do something with the data
  //   },
  // });

  // // Stop receiving data updates from the subscription
  // subscription.unsubscribe();

  async function addTodo() {
    try {
      if (!formState.name || !formState.description) return;
      const todo = { ...formState };
      console.log(todo);
      setTodos([...todos, todo]);
      setFormState(initialState);
      await API.graphql(graphqlOperation(createTodo, { input: todo }));
    } catch (err) {
      console.log("error creating todo:", err);
    }
  }

  async function myupdateTodo() {
    try {
      console.log("in update Todo");
      const todo = {
        id: "96ff25a1-ccb1-486d-99da-04916df33ccb",
        name: "hello2",
        description: "111",
      };
      let mytemp = await API.graphql(
        graphqlOperation(updateTodo, { input: todo })
      );
      console.log(mytemp);
      console.log("*");
    } catch (err) {
      console.log("error creating todo:", err);
    }
  }

  return (
    <div style={styles.container}>
      {/* <h2>NFT 2 TV</h2> */}
      {/* <input
        onChange={(event) => setInput("name", event.target.value)}
        style={styles.input}
        value={formState.name}
        placeholder="Name"
      />
      <input
        onChange={(event) => setInput("description", event.target.value)}
        style={styles.input}
        value={formState.description}
        placeholder="Description"
      />
      <button style={styles.button} onClick={addTodo}>
        Create Todo
      </button>
      <button style={styles.button} onClick={myupdateTodo}>
        Update Todo
      </button> */}
      <div style={styles.todoName}>
        <h2>NFT 2 TV</h2>
        <p>
          {name}
          <br />
          {price} ETH
        </p>
        {/* <p>sold on Open sea at {udpdatedAt}</p> */}

        {/* <Carousel>
          <div>
            <img src={url} />
            <p className="legend">Live</p>
          </div>
          <div>
            <img src={url} />
            <p className="legend">Legend 2</p>
          </div>
          <div>
            <img src={url} />
            <p className="legend">Legend 3</p>
          </div>
        </Carousel> */}
        {/* <img height={80%} src="643.png" />
        <img height={200} src={url} /> */}

        <LazyLoad>
          <img src={url} width="50%" />
        </LazyLoad>

        {/* <img src={url} width="80%" /> */}
        {/* <img src="/nfts/logo192.png" width="20%" /> */}
        {/* <p>{url}</p>
        <Car />
        <CastProvider>
          <CastButton />
          <Videocomponent />
        </CastProvider> */}
      </div>

      {/* {todos.map((todo, index) => {
        if (index == 0) {
          return (
            <div key={todo.id ? todo.id : index} style={styles.todo}>
              <p style={styles.todoName}>{todo.name}</p>
              <p style={styles.todoDescription}>{todo.description}</p>
            </div>
          );
        }
      })} */}
    </div>
  );
};
const styles = {
  container: {
    // width: 600,
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    padding: 80,
    backgroundColor: "black",
  },
  todo: { marginBottom: 15 },
  input: {
    border: "none",
    backgroundColor: "#ddd",
    marginBottom: 10,
    padding: 8,
    fontSize: 18,
  },
  todoName: { fontSize: 20, fontWeight: "bold", color: "white" },
  todoDescription: { marginBottom: 0 },
  button: {
    backgroundColor: "black",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
  },
};

export default App;
